import {React, useState} from "react";
import {Helmet} from "react-helmet";
import {StatisticsOneEntry, StatisticsTwoEntries, StatisticsThreeEntries} from "../statistics/StatisticsTemplates";
import StatisticsAffiliate from "./StatisticsAffiliate";

function StatisticsBasic(){
    const [selectedText, setSelectedText] = useState('Mean') //default selected value
    return(
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Learn the core concepts of basic statistics." />
          <meta property="og:description" content="Covers mean, geometric mean, harmonic mean, median, mode, variance, standard deviation, percentile and zscore."/>
        </Helmet>              

            <section className="text-base-content body-font relative dark:text-white">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <div className="flex flex-col text-center w-full mb-12">
                    <h2 className="card-title flex justify-center items-center py-2">Basic Statistics</h2>
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={selectedText} onChange={event => setSelectedText(event.target.value)}>
                                <option value="Mean">Mean</option>
                                <option value="GeoMean">Geometric Mean</option>
                                <option value="HarMean">Harmonic Mean</option>
                                <option value="Median">Median</option>
                                <option value="Mode">Mode</option>
                                <option value="PopVar">Population variance</option>
                                <option value="PopStd">Population standard deviation</option>
                                <option value="SampVar">Sample variance</option>
                                <option value="SampStd">Sample standard deviation</option>
                                <option value="NPer">Nth percentile</option>
                                <option value="ZPop">Zscore from population</option>
                                <option value="ZSamp">Zscore from sample</option>
                                <option value="ZMeanStd">Zscore from mean and standard deviation</option>
                            </select>
                        </div>
                        <div>
                            {selectedText==="Mean" && <StatisticsOneEntry endpoint={"mean"}></StatisticsOneEntry>}
                            {selectedText==="GeoMean" && <StatisticsOneEntry endpoint={"geomean"}></StatisticsOneEntry>   }
                            {selectedText==="HarMean" && <StatisticsOneEntry endpoint={"harmean"}></StatisticsOneEntry>   }
                            {selectedText==="Median" && <StatisticsOneEntry endpoint={"median"}></StatisticsOneEntry>}
                            {selectedText==="Mode" && <StatisticsOneEntry endpoint={"mode"}></StatisticsOneEntry>   }
                            {selectedText==="PopVar" && <StatisticsOneEntry endpoint={"popvar"}></StatisticsOneEntry>   }
                            {selectedText==="PopStd" && <StatisticsOneEntry endpoint={"popstd"}></StatisticsOneEntry>}
                            {selectedText==="SampVar" && <StatisticsOneEntry endpoint={"sampvar"}></StatisticsOneEntry>   }
                            {selectedText==="SampStd" && <StatisticsOneEntry endpoint={"sampstd"}></StatisticsOneEntry>   }
                            {selectedText==="NPer" && <StatisticsTwoEntries endpoint={"nper"} inputPlaceHolder={"N"}></StatisticsTwoEntries>}
                            {selectedText==="ZPop" && <StatisticsTwoEntries endpoint={"zpop"} inputPlaceHolder={"Data point"}></StatisticsTwoEntries>}
                            {selectedText==="ZSamp" && <StatisticsTwoEntries endpoint={"zsamp"} inputPlaceHolder={"Data point"}></StatisticsTwoEntries>}
                            {selectedText==="ZMeanStd" && <StatisticsThreeEntries endpoint={"zmeanstd"}></StatisticsThreeEntries>}
                        </div>
                        <div className="py-12"> 
                        {/*  <StatisticsAffiliate />  */}
                        </div>
                        <div className="py-24">
                            
                        </div>

                    
                    </div>
                </div>
            </section>


        </>
    )
}
export default StatisticsBasic;
