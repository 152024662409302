import {React, useState} from "react";
import {Helmet} from "react-helmet";
import TrigonometryAffiliate from "./TrigonometryAffiliate";

function RegularFunctions({calculus, value}){
    const [selectedText2, setSelectedText2] = useState('degrees') //default selected value

    const [outputText, setOutputText] = useState('')
    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');


        const angletype = selectedText2;

        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/trigonometry/basic/${calculus}/${value}/${angletype}`;
   
        if(value !== '' ){
            fetch(url)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter numerical values!");
        }      
              
    }

    return(
        <div>

            <section className="text-base-content body-font relative">
                <div className="py-1 mx-auto max-w-lg">
                    <div className="flex flex-col text-center w-full mb-12">
                        <div className="">
                            <select className="select select-bordered w-full" value={selectedText2} onChange={event => setSelectedText2(event.target.value)}>
                                <option value="degrees">Degrees</option>
                                <option value="radians">Radians</option>
                                <option value="gradians">Gradians</option>
                            </select>
                        </div>

                        <div className="py-2">
                            <button className="btn bg-blue-600 hover:bg-blue-800 text-white w-full" onClick={startCalculate}>Calculate</button>
                        </div>
                        <div className="flex justify-center items-center">
                            { customError && <p className="py-1 text-sm">{customError}</p> }
                        </div>
                        <div className="flex justify-center items-center">
                            { error && <p className="py-1 text-sm">{error.message}</p> }
                        </div>
                        <div className="flex justify-center items-center">
                            { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
                        </div>
                        <div>
                            <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
                        </div>
                        <div className="py-12"> 
                        {/*  <TrigonometryAffiliate />  */}
                        </div>
                        <div className="py-24">
                            
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

function InverseFunctions({calculus, value}){
    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');

        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/trigonometry/angle/${calculus}/${value}`;
   
        if(value !== '' ){
            fetch(url)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter numerical values!");
        }      
              
    }

    return(
        <div>

            <section className="text-base-content body-font relative">
                <div className="py-1 mx-auto max-w-lg">
                    <div className="flex flex-col text-center w-full mb-12">
                        <div className="py-2">
                            <button className="btn bg-purple-600 hover:bg-purple-800 text-white w-full" onClick={startCalculate}>Calculate</button>
                        </div>
                        <div className="flex justify-center items-center">
                            { customError && <p className="py-1 text-sm">{customError}</p> }
                        </div>
                        <div className="flex justify-center items-center">
                            { error && <p className="py-1 text-sm">{error.message}</p> }
                        </div>
                        <div className="flex justify-center items-center">
                            { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
                        </div>
                        <div>
                            <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
                        </div>
                        <div className="py-24">
                            
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

function TrigonometryFunction(){
    const [selectedText1, setSelectedText1] = useState('cos') //default selected value
    const [searchItem1, setSearchItem1] = useState('')
    return(
        <div>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Trigonometric functions" />
          <meta property="og:description" content="Calculate cosine, sine, tangent, hyperbolic, inverse hyperbolic."/>
        </Helmet>
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <div className="flex flex-col text-center w-full mb-12">
                    <h2 className="sm:text-2xl text-xl mx-auto font-bold dark:text-gray-200 py-4">Trigonometric Functions</h2>
                        <div className="flex flex-row py-2 mt-6">
                            <div className="basis-2/3">
                                <select className="select select-bordered w-full" value={selectedText1} onChange={event => setSelectedText1(event.target.value)}>
                                    <option value="cos">Cosine</option>
                                    <option value="sin">Sine</option>
                                    <option value="tan">Tangent</option>
                                    <option value="cosh">Hyperbolic Cosine</option>
                                    <option value="sinh">Hyperbolic Sine</option>
                                    <option value="tanh">Hyperbolic Tangent</option>
                                    <option value="acos">Inverse Cosine</option>
                                    <option value="asin">Inverse Sine</option>
                                    <option value="atan">Inverse Tangent</option>
                                    <option value="acosh">Inverse Hyperbolic Cosine</option>
                                    <option value="asinh">Inverse Hyperbolic Sine</option>
                                    <option value="atanh">Inverse Hyperbolic Tangent</option>
                                </select>
                            </div>
                            <div className="basis-1/3 pl-2">
                                <div>
                                    <input  type="text" placeholder="value" className="input input-bordered w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input>
                                </div>
                            </div>
                        </div>
                        {selectedText1 === "cos" && <RegularFunctions calculus={"cos"} value={searchItem1}></RegularFunctions>}
                        {selectedText1 === "sin" && <RegularFunctions calculus={"sin"} value={searchItem1}></RegularFunctions>}
                        {selectedText1 === "tan" && <RegularFunctions calculus={"tan"} value={searchItem1}></RegularFunctions>}
                        {selectedText1 === "cosh" && <RegularFunctions calculus={"cosh"} value={searchItem1}></RegularFunctions>}
                        {selectedText1 === "sinh" && <RegularFunctions calculus={"sinh"} value={searchItem1}></RegularFunctions>}
                        {selectedText1 === "tanh" && <RegularFunctions calculus={"tanh"} value={searchItem1}></RegularFunctions>}

                        {selectedText1 === "acos" && <InverseFunctions calculus={"acos"} value={searchItem1}></InverseFunctions>}
                        {selectedText1 === "asin" && <InverseFunctions calculus={"asin"} value={searchItem1}></InverseFunctions>}
                        {selectedText1 === "atan" && <InverseFunctions calculus={"atan"} value={searchItem1}></InverseFunctions>}
                        {selectedText1 === "acosh" && <InverseFunctions calculus={"acosh"} value={searchItem1}></InverseFunctions>}
                        {selectedText1 === "asinh" && <InverseFunctions calculus={"asinh"} value={searchItem1}></InverseFunctions>}
                        {selectedText1 === "atanh" && <InverseFunctions calculus={"atanh"} value={searchItem1}></InverseFunctions>}
                        <div className="py-24">
                            
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default TrigonometryFunction;