import {TopographyFiveEntries} from "./TopographyTemplates";
    import {React} from "react";
    import {Helmet} from "react-helmet";
    import area_image from "../../assets/images/quadrilateral.png";
    import TopographyAffiliate from "./TopographyAffiliate";



function TopographyVolume(){
    return(
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Area of a quadrilateral." />
          <meta property="og:description" content="Calculate the area of a quadrilateral from its sides lengths and the radius of the incribed circle."/>
        </Helmet>         
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h2 className="card-title flex justify-center items-center py-2">Area of a quadrilateral</h2>
                    <div className="flex flex-col text-center w-full mb-12">
                        <div>
                            <TopographyFiveEntries endpoint={"quadrilatere"} image={area_image} 
                            placeHolder1={"a"} placeHolder2="b" placeHolder3={"c"} placeHolder4="d"  placeHolder5={"r"}>
                                </TopographyFiveEntries>     
                        </div>
                        <div className="py-12"> 
                        {/*  <TopographyAffiliate />  */}
                        </div>
                        <div className="py-24">
                            
                        </div>

                    
                    </div>
                </div>
            </section>
            </>
    )
}

export default TopographyVolume;