import React from "react";
import {Helmet} from "react-helmet";
import area_image from "../../assets/images/flat-geometric-models-background_23-2148373965.avif";
import volume_image from "../../assets/images/high-angle-geometric-forms_23-2148830230.avif";



function SurfaceMain(){
    return(
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Areas and volumes." />
          <meta property="og:description" content="Covers calculations of the areas and volumes of various geometric figures such as pyramids, pentagons, and ellipsoids."/>
        </Helmet>         
            {/**Description */}
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-2 mx-auto">
                    <div className="flex flex-col text-justify w-full mb-12">
                        <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">&nbsp;</h1>
                        <h2 className="text-black-500 dark:text-white">
                        Surface Volume permits calculating the areas and volumes of various geometric figures such as pyramids, pentagons, and ellipsoids. 
                        Here is the list of the geometric figures covered: 
                        </h2>
                        <ul className="space-y-4 py-4 px-12">
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white"> Circle</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-red-100 stroke-red-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white"> Sphere</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-purple-100 stroke-purple-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white"> Pentagon</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white"> Icosahedron</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-red-100 stroke-red-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white"> Rectangle</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-purple-100 stroke-purple-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white"> Triangle</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white"> Cylinder</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-red-100 stroke-red-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white"> Annulus</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-purple-100 stroke-purple-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white"> Pyramid</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white"> Cone</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-red-100 stroke-red-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white">Box</p>
                            </li>
                        </ul>
                        <div className=" dark:text-white">
                            <span>The native Application can be downloaded on the different applications stores: </span>
                            <a className="btn btn-ghost btn-circle btn-sm mr-4" href="https://play.google.com/store/apps/details?id=tntechnology.app.surface_volume" target={"_blank"} rel="noopener noreferrer">
                                <svg width="20px" height="20px" viewBox="19.932999999999996 68.509 228.155 228.155" xmlns="http://www.w3.org/2000/svg"><path d="M101.885 207.092c7.865 0 14.241 6.376 14.241 14.241v61.09c0 7.865-6.376 14.24-14.241 14.24-7.864 0-14.24-6.375-14.24-14.24v-61.09c0-7.864 6.376-14.24 14.24-14.24z" fill="#a4c639"/><path d="M69.374 133.645c-.047.54-.088 1.086-.088 1.638v92.557c0 9.954 7.879 17.973 17.66 17.973h94.124c9.782 0 17.661-8.02 17.661-17.973v-92.557c0-.552-.02-1.1-.066-1.638H69.374z" fill="#a4c639"/><path d="M166.133 207.092c7.865 0 14.241 6.376 14.241 14.241v61.09c0 7.865-6.376 14.24-14.241 14.24-7.864 0-14.24-6.375-14.24-14.24v-61.09c0-7.864 6.376-14.24 14.24-14.24zM46.405 141.882c7.864 0 14.24 6.376 14.24 14.241v61.09c0 7.865-6.376 14.241-14.24 14.241-7.865 0-14.241-6.376-14.241-14.24v-61.09c-.001-7.865 6.375-14.242 14.241-14.242zM221.614 141.882c7.864 0 14.24 6.376 14.24 14.241v61.09c0 7.865-6.376 14.241-14.24 14.241-7.865 0-14.241-6.376-14.241-14.24v-61.09c0-7.865 6.376-14.242 14.241-14.242zM69.79 127.565c.396-28.43 25.21-51.74 57.062-54.812h14.312c31.854 3.073 56.666 26.384 57.062 54.812H69.79z" fill="#a4c639"/><path d="M74.743 70.009l15.022 26.02M193.276 70.009l-15.023 26.02" fill="none" stroke="#a4c639" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/><path d="M114.878 102.087c.012 3.974-3.277 7.205-7.347 7.216-4.068.01-7.376-3.202-7.388-7.176v-.04c-.011-3.975 3.278-7.205 7.347-7.216 4.068-.011 7.376 3.2 7.388 7.176v.04zM169.874 102.087c.012 3.974-3.277 7.205-7.347 7.216-4.068.01-7.376-3.202-7.388-7.176v-.04c-.011-3.975 3.278-7.205 7.347-7.216 4.068-.011 7.376 3.2 7.388 7.176v.04z" fill="#ffffff"/></svg>
                                </a>
                                <a className="btn btn-ghost btn-circle btn-sm" href="https://www.microsoft.com/store/apps/9NQZ5W6R185D" target={"_blank"} rel="noopener noreferrer">
                                <svg width="20px" height="20px" viewBox="-0.5 0 257 257" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid"><path d="M0 36.357L104.62 22.11l.045 100.914-104.57.595L0 36.358zm104.57 98.293l.08 101.002L.081 221.275l-.006-87.302 104.494.677zm12.682-114.405L255.968 0v121.74l-138.716 1.1V20.246zM256 135.6l-.033 121.191-138.716-19.578-.194-101.84L256 135.6z" fill="#00ADEF"/></svg>
                                </a> . <span  className="text-black-500 dark:text-white">You will find below the sub-categories of Surface Volume. Please select one of the options to proceed further.</span> 
                            </div>
                    </div>
                </div>
            </section>
            {/**Topics */}

            <section className="text-base-content body-font dark:text-white">
                <div className="container px-5 py-4 mx-auto flex items-center justify-center">
                    <div className="flex flex-wrap -mx-4 -mb-10 text-center">
                        <div className="sm:w-1/2 mb-10 px-4">
                            {/**areas*/}
                                <div className="card shadow w-80 m-4">
                                    <figure>
                                        <img src={area_image}  alt="slope" />
                                    </figure>
                                    <div className="card-body flex items-center">
                                        <h2 className="card-title">Areas</h2>
                                        <p className="text-black-500 dark:text-white">
                                        Calculate the areas of geometric figures.
                                        </p>
                                        <a className="btn bg-gray-600  hover:bg-gray-900 text-white btn-sm" href="/surface-area">Access</a>
                                    </div>
                                </div>
                        </div>
                        <div className="sm:w-1/2 mb-10 px-4">
                            {/**volumes*/}
                            <div className="card shadow w-80 m-4">
                                <figure>
                                    <img src={volume_image}  alt="slope" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title">Volumes</h2>
                                    <p className="text-black-500 dark:text-white">
                                    Calculate volumes of geometric figures.
                                    </p>
                                    <a className="btn bg-blue-500 hover:bg-blue-800 text-white btn-sm" href="/surface-volume">Access</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}
export default SurfaceMain;
