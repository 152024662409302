import { FinanceTwoEntries,FinanceFourEntries}
 from "./FinanceTemplates";
    import {React, useState} from "react";
    import {Helmet} from "react-helmet";
    import FinanceAffiliate from "./FinanceAffiliate";



function FinanceBasic(){
    const [selectedText, setSelectedText] = useState('Assets') //default selected value
    return(
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Basic finance" />
          <meta property="og:description" 
          content="Calculate earnings before interest and taxes (EBIT), equity, gross profit, liabilities, net profit, sales revenue, etc."/>
        </Helmet>         
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h2 className="card-title flex justify-center items-center py-2">Basic Finance</h2>
                    <div className="flex flex-col text-center w-full mb-2">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={selectedText} onChange={event => setSelectedText(event.target.value)}>
                            <option value="Assets">Assets </option>
                            <option value="EarningsBeforeInterest">Earnings Before Interest and Taxes (EBIT) </option>
                            <option value="Equity">Equity </option>
                            <option value="GrossProfit">Gross Profit </option>
                            <option value="Liabilities">Liabilities </option>
                            <option value="NetProfit">Net Profit </option>
                            <option value="OperationProfit">Operation Profit </option>
                            <option value="SalesRevenue">Sales Revenue </option>
                               
                            </select>
                        </div>
                    </div>
                        <div>
                        {selectedText==="Assets" && <> <FinanceTwoEntries endpoint={"basic/assetsfromliabilitiesandequity"} placeHolder1={"Liabilities"}  placeHolder2={"Equity"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="EarningsBeforeInterest" && <> <FinanceTwoEntries endpoint={"basic/earningsbeforeinterestandtaxesfromrevenueandoperatingexpenses"} placeHolder1={"Revenue"}  placeHolder2={"Operating Expenses"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="Equity" && <> <FinanceTwoEntries endpoint={"basic/equityfromassetsandliabilities"} placeHolder1={"Assets"}  placeHolder2={"Liabilities"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="GrossProfit" && <> <FinanceTwoEntries endpoint={"basic/grossprofitfromrevenueandcostofgoodssold(cogs)"} placeHolder1={"Revenue"}  placeHolder2={"Cost Of Goods Sold"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="Liabilities" && <> <FinanceTwoEntries endpoint={"basic/liabilitiesfromassetsandequity"} placeHolder1={"Assets"}  placeHolder2={"Equity"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="NetProfit" && <> <FinanceFourEntries endpoint={"basic/netprofitfromgrossprofitoperatingexpensestaxesandinterest"} placeHolder1={"Gross Profit"}  placeHolder2={"Operating Expenses"} placeHolder3={"Taxes"} placeHolder4={"Interest"}></FinanceFourEntries>   </>}    
                        {selectedText==="OperationProfit" && <> <FinanceTwoEntries endpoint={"basic/operationprofitfromgrossprofitandoperatingexpenses"} placeHolder1={"Gross Profit"}  placeHolder2={"Operating Expenses"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="SalesRevenue" && <> <FinanceTwoEntries endpoint={"basic/salesrevenuefromgrosssalesandsalesofreturnsandallowances"} placeHolder1={"Gross Sales"}  placeHolder2={"Sales of Returns and Allowances"} ></FinanceTwoEntries>   </>}    



                        </div>
                        <div className="py-12"> 
                        {/*  <FinanceAffiliate />  */}
                        </div>
                        <div className="py-24">
 
                        </div>
                </div>
            </section>
            </>
    )
}

export default FinanceBasic;