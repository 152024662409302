import { FinanceTwoEntries,FinanceThreeEntries,FinanceFourEntries}
 from "./FinanceTemplates";
    import {React, useState} from "react";
    import {Helmet} from "react-helmet";
    import FinanceAffiliate from "./FinanceAffiliate";



function FinanceBanking(){
    const [selectedText, setSelectedText] = useState('AnnualPercentageYield') //default selected value
    return(
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Banking" />
          <meta property="og:description" 
          content="Calculate annual percentage yield, balloon loan payment, compound interest, debt to income ratio, loan to deposit ratio, simple interest, etc."/>
        </Helmet>         
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h2 className="card-title flex justify-center items-center py-2">Banking</h2>
                    <div className="flex flex-col text-center w-full mb-2">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={selectedText} onChange={event => setSelectedText(event.target.value)}>
                            <option value="AnnualPercentageYield">Annual Percentage Yield </option>
                                <option value="BalloonLoanPayment">Balloon Loan Payment </option>
                                <option value="CompoundInterest">Compound Interest </option>
                                <option value="ContinuousCompounding">Continuous Compounding </option>
                                <option value="DebttoIncome">Debt to Income Ratio </option>
                                <option value="BalloonBalanceof">Balloon Balance of Loan </option>
                                <option value="LoanPayment">Loan Payment </option>
                                <option value="RemainingBalanceon">Remaining Balance on Loan </option>
                                <option value="LoantoDeposit">Loan to Deposit Ratio </option>
                                <option value="LoantoValue">Loan to Value Ratio </option>
                                <option value="SimpleInterest">Simple Interest </option>
                                <option value="SimpleInterestRate">Simple Interest Rate </option>
                                <option value="SimpleInterestPrincipal">Simple Interest Principal </option>
                                <option value="SimpleInterestTime">Simple Interest Time </option>
                               
                            </select>
                        </div>
                    </div>
                        <div>
                        {selectedText==="AnnualPercentageYield" && <> <FinanceTwoEntries endpoint={"banking/annualpercentageyieldfromstatedannualinterestrateandnumberoftimescompounded"} placeHolder1={"Stated Annual Interest Rate"}  placeHolder2={"Number of Times Compounded"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="BalloonLoanPayment" && <> <FinanceFourEntries endpoint={"banking/balloonloanpaymentfrompresentvalueballoonamountrateperperiodandnumberofperiods"} placeHolder1={"Present Value"}  placeHolder2={"Balloon Amount"} placeHolder3={"Rate Per Period"} placeHolder4={"Number of Periods"}></FinanceFourEntries>   </>}    
                        {selectedText==="CompoundInterest" && <> <FinanceThreeEntries endpoint={"banking/compoundinterestfromprincipalrateperperiodandnumberofperiods"} placeHolder1={"Principal"}  placeHolder2={"Rate per Period"} placeHolder3={"Number of Periods"}></FinanceThreeEntries>   </>}    
                        {selectedText==="ContinuousCompounding" && <> <FinanceThreeEntries endpoint={"banking/continuouscompoundingfromprincipalrateandtime"} placeHolder1={"Principal"}  placeHolder2={"Rate"} placeHolder3={"Time"}></FinanceThreeEntries>   </>}    
                        {selectedText==="DebttoIncome" && <> <FinanceTwoEntries endpoint={"banking/debttoincomeratiofrommonthlydebtpaymentsandgrossmonthlyincome"} placeHolder1={"Monthly Debt Payments"}  placeHolder2={"Gross Monthly Income"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="BalloonBalanceof" && <> <FinanceFourEntries endpoint={"banking/balloonbalanceofloanfrompresentvaluepaymentrateperpaymentandnumberofpayments"} placeHolder1={"Present Value"}  placeHolder2={"Payment"} placeHolder3={"Rate Per Payment"} placeHolder4={"Number Of Payments"}></FinanceFourEntries>   </>}    
                        {selectedText==="LoanPayment" && <> <FinanceThreeEntries endpoint={"banking/loanpaymentfrompresentvaluerateperperiodandnumberofperiods"} placeHolder1={"Present Value"}  placeHolder2={"Rate per Period"} placeHolder3={"Number of Periods"}></FinanceThreeEntries>   </>}    
                        {selectedText==="RemainingBalanceon" && <> <FinanceFourEntries endpoint={"banking/remainingbalanceonloanfrompresentvaluepaymentrateperpaymentandnumberofpayments"} placeHolder1={"Present Value"}  placeHolder2={"Payment"} placeHolder3={"Rate Per Payment"} placeHolder4={"Number Of Payments"}></FinanceFourEntries>   </>}    
                        {selectedText==="LoantoDeposit" && <> <FinanceTwoEntries endpoint={"banking/loantodepositratiofromloansanddeposits"} placeHolder1={"Loans"}  placeHolder2={"Deposits"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="LoantoValue" && <> <FinanceTwoEntries endpoint={"banking/loantovalueratiofromloanamountandvalueofcollateral"} placeHolder1={"Loan Amount"}  placeHolder2={"Value of Collateral"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="SimpleInterest" && <> <FinanceThreeEntries endpoint={"banking/simpleinterestfromprincipalrateandtime"} placeHolder1={"Principal"}  placeHolder2={"Rate"} placeHolder3={"Time"}></FinanceThreeEntries>   </>}    
                        {selectedText==="SimpleInterestRate" && <> <FinanceThreeEntries endpoint={"banking/simpleinterestratefromprincipalinterestandtime"} placeHolder1={"Principal"}  placeHolder2={"Interest"} placeHolder3={"Time"}></FinanceThreeEntries>   </>}    
                        {selectedText==="SimpleInterestPrincipal" && <> <FinanceThreeEntries endpoint={"banking/simpleinterestprincipalfrominterestrateandtime"} placeHolder1={"Interest"}  placeHolder2={"Rate"} placeHolder3={"Time"}></FinanceThreeEntries>   </>}    
                        {selectedText==="SimpleInterestTime" && <> <FinanceThreeEntries endpoint={"banking/simpleinteresttimefromprincipalinterestandrate"} placeHolder1={"Principal"}  placeHolder2={"Interest"} placeHolder3={"Rate"}></FinanceThreeEntries>   </>}      



                        </div>
                        <div className="py-12"> 
                        {/*  <FinanceAffiliate />  */}
                        </div>
                        <div className="py-24">
 
                        </div>
                </div>
            </section>
            </>
    )
}

export default FinanceBanking;