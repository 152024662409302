import {FinanceOneEntry, FinanceTwoEntries,FinanceThreeEntries,FinanceFiveEntries}
 from "./FinanceTemplates";
    import {React, useState} from "react";
    import {Helmet} from "react-helmet";
    import FinanceAffiliate from "./FinanceAffiliate";



function FinanceCorporate(){
    const [selectedText, setSelectedText] = useState('AssettoSales') //default selected value
    return(
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Corporate finance" />
          <meta property="og:description" 
          content="Calculate assets to sales ratio, asset turnover ratio, contribution margin, days in inventory, discounted payback period, free cash flow to equity, net working capital, retention ratio, etc."/>
        </Helmet>        
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h2 className="card-title flex justify-center items-center py-1">Corporate</h2>
                    <div className="flex flex-col text-center w-full mb-2">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={selectedText} onChange={event => setSelectedText(event.target.value)}>
                            <option value="AssettoSales">Asset to Sales Ratio </option>
                            <option value="AssetTurnoverRatio">Asset Turnover Ratio </option>
                            <option value="AverageCollectionPeriod">Average Collection Period </option>
                            <option value="ContributionMargin">Contribution Margin </option>
                            <option value="CurrentRatio">Current Ratio </option>
                            <option value="DaysInInventory">Days In Inventory </option>
                            <option value="DebtCoverageRatio">Debt Coverage Ratio </option>
                            <option value="DebtRatio">Debt Ratio </option>
                            <option value="DebttoEquity">Debt to Equity Ratio </option>
                            <option value="DiscountedPaybackPeriod">Discounted Payback Period </option>
                            <option value="EquivalentAnnualAnnuity">Equivalent Annual Annuity </option>
                            <option value="FreeCashFlow1">Free Cash Flow to Equity </option>
                            <option value="FreeCashFlow2">Free Cash Flow to Firm </option>
                            <option value="InterestCoverageRatio">Interest Coverage Ratio </option>
                            <option value="InventoryTurnoverRatio">Inventory Turnover Ratio </option>
                            <option value="NetProfitMargin">Net Profit Margin </option>
                            <option value="NetWorkingCapital">Net Working Capital </option>
                            <option value="PaybackPeriod">Payback Period </option>
                            <option value="QuickRatio">Quick Ratio </option>
                            <option value="ReceivablesTurnoverRatio">Receivables Turnover Ratio </option>
                            <option value="RetentionRatio">Retention Ratio </option>
                            <option value="ReturnonAssets">Return on Assets </option>
                            <option value="ReturnonEquity">Return on Equity </option>
                            <option value="ReturnonInvestment">Return on Investment </option>
                               
                            </select>
                        </div>
                    </div>
                        <div>
                        {selectedText==="AssettoSales" && <> <FinanceTwoEntries endpoint={"corporate/assettosalesratiofromtotalassetsandsalesrevenue"} placeHolder1={"Total Assets"}  placeHolder2={"Sales Revenue"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="AssetTurnoverRatio" && <> <FinanceTwoEntries endpoint={"corporate/assetturnoverratiofromsalesrevenueandtotalassets"} placeHolder1={"Sales Revenue"}  placeHolder2={"Total Assets"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="AverageCollectionPeriod" && <> <FinanceOneEntry endpoint={"corporate/averagecollectionperiodfromreceivablesturnover"} placeHolder1={"Receivables Turnover"}  ></FinanceOneEntry>   </>}    
                        {selectedText==="ContributionMargin" && <> <FinanceTwoEntries endpoint={"corporate/contributionmarginfrompriceperproductandvariablecostperproduct"} placeHolder1={"Price per Product"}  placeHolder2={"Variable Cost Per Product"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="CurrentRatio" && <> <FinanceTwoEntries endpoint={"corporate/currentratiofromcurrentassetsandcurrentliabilities"} placeHolder1={"Current Assets"}  placeHolder2={"Current Liabilities"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="DaysInInventory" && <> <FinanceOneEntry endpoint={"corporate/daysininventoryfrominventoryturnover"} placeHolder1={"Inventory Turnover"}  ></FinanceOneEntry>   </>}    
                        {selectedText==="DebtCoverageRatio" && <> <FinanceTwoEntries endpoint={"corporate/debtcoverageratiofromnetoperatingincomeanddebtservice"} placeHolder1={"Net Operating Income"}  placeHolder2={"Debt Service"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="DebtRatio" && <> <FinanceTwoEntries endpoint={"corporate/debtratiofromtotalliabilitiesandtotalassets"} placeHolder1={"Total Liabilities"}  placeHolder2={"Total Assets"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="DebttoEquity" && <> <FinanceTwoEntries endpoint={"corporate/debttoequityratiofromtotalliabilitiesandtotalequity"} placeHolder1={"Total Liabilities"}  placeHolder2={"Total Equity"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="DiscountedPaybackPeriod" && <> <FinanceThreeEntries endpoint={"corporate/discountedpaybackperiodfrominitialinvestmentrateandperiodiccashflow"} placeHolder1={"Initial Investment"}  placeHolder2={"Rate"} placeHolder3={"Periodic Cash Flow"}></FinanceThreeEntries>   </>}    
                        {selectedText==="EquivalentAnnualAnnuity" && <> <FinanceThreeEntries endpoint={"corporate/equivalentannualannuityfromnetpresentvaluerateperperiodandnumberofperiods"} placeHolder1={"Net Present Value"}  placeHolder2={"Rate per Period"} placeHolder3={"Number of Periods"}></FinanceThreeEntries>   </>}    
                        {selectedText==="FreeCashFlow1" && <> <FinanceFiveEntries endpoint={"corporate/freecashflowtoequityfromnetincomedepreciationandamortizationcapitalexpenditureschangeinworkingcapitalandnetborrowing"} placeHolder1={"Net Income"}  placeHolder2={"Depreciation and Amortization"} placeHolder3={"Capital Expenditures"} placeHolder4={"Change in Working Capital"} placeHolder5={"Net Borrowing"}></FinanceFiveEntries>   </>}    
                        {selectedText==="FreeCashFlow2" && <> <FinanceFiveEntries endpoint={"corporate/freecashflowtofirmfromebittaxratedepreciationandamortizationcapitalexpendituresandchangeinworkingcapital"} placeHolder1={"EBIT"}  placeHolder2={"Tax Rate"} placeHolder3={"Depreciation and Amortization"} placeHolder4={"Capital Expenditures"} placeHolder5={"Change in Working Capital"}></FinanceFiveEntries>   </>}    
                        {selectedText==="InterestCoverageRatio" && <> <FinanceTwoEntries endpoint={"corporate/interestcoverageratiofromebitandinterestexpense"} placeHolder1={"EBIT"}  placeHolder2={"Interest Expense"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="InventoryTurnoverRatio" && <> <FinanceTwoEntries endpoint={"corporate/inventoryturnoverratiofromsalesandinventory"} placeHolder1={"Sales"}  placeHolder2={"Inventory"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="NetProfitMargin" && <> <FinanceTwoEntries endpoint={"corporate/netprofitmarginfromnetincomeandsalesrevenue"} placeHolder1={"Net Income"}  placeHolder2={"Sales Revenue"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="NetWorkingCapital" && <> <FinanceTwoEntries endpoint={"corporate/networkingcapitalfromcurrentassetsandcurrentliabilities"} placeHolder1={"Current Assets"}  placeHolder2={"Current Liabilities"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="PaybackPeriod" && <> <FinanceTwoEntries endpoint={"corporate/paybackperiodfrominitialinvestmentandperiodiccashflow"} placeHolder1={"Initial Investment"}  placeHolder2={"Periodic Cash Flow"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="QuickRatio" && <> <FinanceTwoEntries endpoint={"corporate/quickratiofromquickassetsandcurrentliabilities"} placeHolder1={"Quick Assets"}  placeHolder2={"Current Liabilities"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="ReceivablesTurnoverRatio" && <> <FinanceTwoEntries endpoint={"corporate/receivablesturnoverratiofromsalesrevenueandaverageaccountsreceivable"} placeHolder1={"Sales Revenue"}  placeHolder2={"Average Accounts Receivable"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="RetentionRatio" && <> <FinanceTwoEntries endpoint={"corporate/retentionratiofromnetincomeanddividends"} placeHolder1={"Net Income"}  placeHolder2={"Dividends"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="ReturnonAssets" && <> <FinanceTwoEntries endpoint={"corporate/returnonassetsfromnetincomeandaveragetotalassets"} placeHolder1={"Net Income"}  placeHolder2={"Average Total Assets"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="ReturnonEquity" && <> <FinanceTwoEntries endpoint={"corporate/returnonequityfromnetincomeandaveragestockholdersequity"} placeHolder1={"Net Income"}  placeHolder2={"Average Stockholders Equity"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="ReturnonInvestment" && <> <FinanceTwoEntries endpoint={"corporate/returnoninvestmentfromearningsandinitialinvestment"} placeHolder1={"Earnings"}  placeHolder2={"Initial Investment"} ></FinanceTwoEntries>   </>}    



                        </div>
                        <div className="py-12"> 
                        {/*  <FinanceAffiliate />  */}
                        </div>
                        <div className="py-24">
 
                        </div>
                        <div className="py-24">
 
                        </div>
                </div>
            </section>
            </>
    )
}

export default FinanceCorporate;