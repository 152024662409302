import {AlgebraOneEntry, AlgebraTwoEntries, AlgebraThreeEntries,AlgebraFourEntries} from "./AlgebraTemplates";
import AlgebraAffiliate from "./AlgebraAffiliate";
    import {React, useState} from "react";
    import {Helmet} from "react-helmet";



function AlgebraSlope(){
    const [selectedText, setSelectedText] = useState('xinter') //default selected value
    return(
      
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Slope, equations and intercepts" />
          <meta property="og:description" content="Find x-intercept, y-intercept, slope, equation, vertex, orientation of functions."/>
        </Helmet>          
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h2 className="card-title flex justify-center items-center py-2">Slopes, intercepts and equations</h2>
                    <div className="flex flex-col text-center w-full mb-2">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={selectedText} onChange={event => setSelectedText(event.target.value)}>
                                <option value="xinter">X intercept of f(x) = ax+b</option>
                                <option value="yinter">Y intercept of f(x) = ax+b</option>
                                <option value="slope">Slope of f(x) = ax+b</option>
                                <option value="slopepts">Slope of a line passing through two points P1 and P2</option>
                                <option value="equationpts">Equation of a line passing through two points P1 and P2</option>
                                <option value="equationslopy">Equation of a line with known slope and Y intercept</option>
                                <option value="equationslopt">Equation of a line with slope S that passes through a point P1</option>
                                <option value="vertex">Vertex of a quadratic function ax²+bx+c</option>
                                <option value="orientation">Orientation of a quadratic function ax²+bx+c</option>
                            </select>
                        </div>
                    </div>
                        <div>
                            {selectedText==="xinter" && <> <AlgebraOneEntry endpoint={"slope/xintercept"}  placeHolder1={"ax+b"} ></AlgebraOneEntry></>}     
                            {selectedText==="yinter" && <><AlgebraOneEntry endpoint={"slope/yintercept"}  placeHolder1={"ax+b"} ></AlgebraOneEntry></>} 
                            {selectedText==="slope" && <><AlgebraOneEntry endpoint={"slope/slopeq"}  placeHolder1={"ax+b"} ></AlgebraOneEntry></>} 
                            {selectedText==="slopepts" && 
                            <><AlgebraFourEntries endpoint={"slope/slopepts"}  placeHolder1={"XP1"} placeHolder2={"YP1"}  placeHolder3={"XP2"} placeHolder4={"YP2"}></AlgebraFourEntries></>} 
                            {selectedText==="equationpts" && 
                            <><AlgebraFourEntries endpoint={"slope/equationpts"}  placeHolder1={"XP1"} placeHolder2={"YP1"}  placeHolder3={"XP2"} placeHolder4={"YP2"}></AlgebraFourEntries></>} 
                            {selectedText==="equationslopy" && 
                            <><AlgebraTwoEntries endpoint={"slope/equationslopy"}  placeHolder1={"Slope"} placeHolder2={"Y-intercept"}  ></AlgebraTwoEntries></>} 
                            {selectedText==="equationslopt" && 
                            <><AlgebraThreeEntries endpoint={"slope/equationslopt"}  placeHolder1={"Slope"} placeHolder2={"XP1"}  placeHolder3={"XP2"} ></AlgebraThreeEntries></>} 
                            {selectedText==="vertex" && 
                            <><AlgebraThreeEntries endpoint={"quadratic/vertex"}  placeHolder1={"a"} placeHolder2={"b"}  placeHolder3={"c"} ></AlgebraThreeEntries></>} 
                            {selectedText==="orientation" && 
                            <><AlgebraThreeEntries endpoint={"quadratic/orientation"}  placeHolder1={"a"} placeHolder2={"b"}  placeHolder3={"c"} ></AlgebraThreeEntries></>} 
                        </div>
                        {(selectedText !== "vertex" && selectedText !== "orientation") &&
                        <div className="card shadow w-full">
                            <p className="px-2">A polynomial must be entered in the form <span className="text-sky-500 hover:text-sky-600">a</span> x + <span className="text-sky-500 hover:text-sky-600">b</span></p>
                            <p className="px-2">Example: <span className="text-sky-500 hover:text-sky-600">2x-7</span></p>
                        </div>}
                        {(selectedText === "vertex" || selectedText === "orientation") &&
                        <div className="card shadow w-full">
                            <p>
                                Enter the numerical values of <b>a,b,c</b> to find the vertex or orientation of the graph of f(x) =
                                <span className="text-sky-500 hover:text-sky-600"> a</span>x² +
                                <span className="text-sky-500 hover:text-sky-600"> b</span>x +
                                <span className="text-sky-500 hover:text-sky-600"> c</span>.
                            </p>
                            <p>For example, in the expression<b> 7x² - 4x + 5</b>, a = 7, b = -4 and c = 5 .</p>
                            <p>In the expression<b> x²  + 2</b>, a = 1, b = 0 and c = 2 .</p>
                            <p>In the expression<b> -x²  + 3x</b>, a = -1, b = 3 and c = 0 .</p>
                        </div>}
                        <div className="py-12"> 
                        {/*  <AlgebraAffiliate />  */}
                        </div>
                        <div className="py-24">
                            
                        </div>

                    
                    
                </div>
            </section>
            </>
    )
}

export default AlgebraSlope;