import { CalculusTwoEntries, 
    AngouriPolynomialGuide,  LimitExample}
 from "./CalculusTemplates";
    import {React} from "react";
    import {Helmet} from "react-helmet";
    import CalculusAffiliate from "./CalculusAffiliate";



function CalculusLimit(){
    return(
        <>
         <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Limits" />
          <meta property="og:description" content="Find the limits of polynomials, trigonometric and exponential functions."/>
        </Helmet>        
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h2 className="card-title flex justify-center items-center py-2">Limits</h2>
                    <div className="flex flex-col text-center w-full mb-2">
                    </div>
                        <div>
                          <> <CalculusTwoEntries endpoint={"limit"}  placeHolder1={"Enter a function "} placeHolder2={"bound (x ->) "}></CalculusTwoEntries> <LimitExample></LimitExample>  </>    
                            
                        </div>
                        <div className="card shadow w-full">
                             <AngouriPolynomialGuide ></AngouriPolynomialGuide> 
                        </div>
                        <div className="py-12"> 
                        {/*  <CalculusAffiliate />  */}
                        </div>
                        <div className="py-24">
 
                        </div>
                </div>
            </section>
            </>
    )
}

export default CalculusLimit;