import {React} from "react";
import {Helmet} from "react-helmet";
import TrigonometryAffiliate from "./TrigonometryAffiliate";

function TrigonometryIdentity(){
    const items = [
        "sin(x) = 1 / csc(x)",
        "cos(x) = 1 / sec(x)",
        "tan(x) = 1 / cot(x)",
        "csc(x) = 1 /sin(x)",
        "sec(x) = 1 /cos(x)",
        "cot(x) = 1 /tan(x)",

        "tan(x) = sin(x) / cos(x)",
        "cot(x) = cos(x) / sin(x)",

        "sin² x + cos² x = 1",
        "cot² x + 1 = csc² x",
        "tan² x + 1 = sec² x",

        "sin(-x) = -sin(x)",
        "csc(-x) = -csc(x)",
        "tan(-x) = -tan(x)",
        "cot(-x) = -cot(x)",
        "cos(-x) = cos(x))",
        "sec(-x) = sec(x)",

        "sin(2x) = 2 sin(x) cos(x)",
        "cos(2x) = cos² (x) - sin²(x)",
        "=  2cos² (x) -1",
        "= 1 - 2sin² (x)",

        "sinα cosβ = 1/2 [sin(α+β) + sin(α-β)]",
        "sinα sinβ = 1/2 [cos(α-β) - cos(α+β)]",
        "cosα sinβ = 1/2 [sin(α+β) - sin(α-β)]",
        "cosα cosβ = 1/2 [cos(α-β) +cos(α+β)]",

        "sinα + sinβ = 2 sin( (α+β)/2 ) cos( (α-β)/2 )",
        "sinα - sinβ = 2 cos( (α+β)/2 ) sin( (α-β)/2 )",
        "cosα + cosβ = 2 cos( (α+β)/2 ) cos( (α-β)/2 )",
        "cosα - cosβ = -2 sin( (α+β)/2 ) sin( (α-β)/2 )",

        "cos(α+β) = cosα cosβ - sinα sinβ",
        "cos(α-β) = cosα cosβ + sinα sinβ",
        "sin(α+β) = sinα cosβ + cosα sinβ",
        "sin(α-β) = sinα cosβ - cosα sinβ"
    ];
    return(
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Trigonometric Identities." />
         
        </Helmet>

            <section className="text-base-content body-font relative">
                <div className="container px-4 py-1 mx-auto max-w-lg">
                    <div className="flex flex-col text-center w-full mb-12">
                    <div className="mx-auto max-w-md">
                        <h2 className="py-4"> <b>Trigonometric Identities</b> </h2>
                        <ul className="space-y-4">
                            {items.map(function(data) {
                                return (
                                    <li  key={data} className="flex items-center">
                                        <svg className="h-6 w-6 flex-none fill-sky-100 stroke-sky-500 stroke-2" xmlns="http://www.w3.org/2000/svg" fill="none" strokeLinejoin="round" strokeLinecap="round">
                                        <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9"/></svg>
                                        <p  className="text-black-500 dark:text-white">{data}</p> 
                                    </li>
                                )
                            })}
                        </ul>


                    </div>
                    <div className="py-12"> 
                        {/*  <TrigonometryAffiliate />  */}
                        </div>
                        <div className="py-24">
                            
                        </div>

                    
                    </div>
                </div>
            </section>


        </>
    )
}
export default TrigonometryIdentity;
