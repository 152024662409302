import {TopographyFourEntries} from "./TopographyTemplates";
    import {React} from "react";
    import {Helmet} from "react-helmet";
import gisement_image from "../../assets/images/gisement.png";
import TopographyAffiliate from "./TopographyAffiliate";

function TopographyBearing(){

    return(
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Bearing and distance." />
          <meta property="og:description" content="Calculate the bearing and distance of two points from their coordinates."/>
        </Helmet> 
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h2 className="card-title flex justify-center items-center py-2">Bearing and distance</h2>
                    <div className="flex flex-col text-center w-full mb-12">
                        <div>
                            <TopographyFourEntries endpoint={"gisement"} image={gisement_image} 
                            placeHolder1={"XA"} placeHolder2={"YA"} placeHolder3={"XB"} placeHolder4={"YB"}></TopographyFourEntries>
                        </div>
                        <div className="py-12"> 
                        {/*  <TopographyAffiliate />  */}
                        </div>
                        <div className="py-24">
                            
                        </div>

                    
                    </div>
                </div>
            </section>
            </>
    )
}

export default TopographyBearing;