import {TopographyThreeEntries,TopographyFourEntries,TopographyFiveEntries} from "./TopographyTemplates";
    import {React, useState} from "react";
    import {Helmet} from "react-helmet";
    import translate_image from "../../assets/images/translation.png";
    import rotate_image from "../../assets/images/rotation.png";
    import transrotate_image from "../../assets/images/transrotation.png";
    import rotranslate_image from "../../assets/images/rotranslation.png";
    import TopographyAffiliate from "./TopographyAffiliate";

function TopographyCoordinate(){
    const [selectedText, setSelectedText] = useState('translate') //default selected value
    return(
        <>
         <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Changes of coordinate systems." />
          <meta property="og:description" content="Calculate the coordinates of a point after translation, rotation of its coordinate system."/>
        </Helmet>        
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h2 className="card-title flex justify-center items-center py-2">Changes of coordinate systems</h2>
                    <div className="flex flex-col text-center w-full mb-12">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={selectedText} onChange={event => setSelectedText(event.target.value)}>
                                <option value="translate">Translate a coordinate system</option>
                                <option value="rotate">Rotate a coordinate system</option>
                                <option value="transrotate">Translate then rotate a coordinate system</option>
                                <option value="rotranslate">Rotate then translate a coordinate system</option>
                            </select>
                        </div>
                        <div>
                            {selectedText==="translate" && <TopographyFourEntries endpoint={"repere/translate"} image={translate_image} placeHolder1={"XO"} placeHolder2="YO" placeHolder3={"XM"} placeHolder4="YM"></TopographyFourEntries>}
                            {selectedText==="rotate" && <TopographyThreeEntries endpoint={"repere/rotate"} image={rotate_image} placeHolder1={"Angle A in gradians"} placeHolder2="XM"  placeHolder3={"YM"}></TopographyThreeEntries>}
                            {selectedText==="transrotate" && <TopographyFiveEntries endpoint={"repere/transrotate"} image={transrotate_image}  placeHolder1={"XO"} placeHolder2="YO" placeHolder3={"XM"} placeHolder4="YM" placeHolder5="Angle A in gradians"></TopographyFiveEntries>}
                            {selectedText==="rotranslate" && <TopographyFiveEntries endpoint={"repere/rotranslate"} image={rotranslate_image}  placeHolder1={"XO'"} placeHolder2="YO'" placeHolder3={"XM'"} placeHolder4="YM'" placeHolder5="Angle A in gradians"></TopographyFiveEntries>}
                           
                        </div>
                        <div className="py-12"> 
                        {/*  <TopographyAffiliate />  */}
                        </div>
                        <div className="py-24">
                            
                        </div>

                    
                    </div>
                </div>
            </section>
            </>
    )
}

export default TopographyCoordinate;