import {React} from "react";
import {Helmet} from "react-helmet";
import ScienceTemplate from "./ScienceTemplates";



function SciencePhysics(){
    return(
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Physical constants" />
        </Helmet>        
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h2 className="card-title flex justify-center items-center py-2">Physical constants</h2>
                        <div>
                            <> <ScienceTemplate endpoint={"physics"} ></ScienceTemplate>   </>     
                        </div>
                        <div className="card shadow w-full">
                             
                        </div>
                        <div className="py-24">
 
                        </div>
                </div>
            </section>
            </>
    )
}

export default SciencePhysics;